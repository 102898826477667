/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDu7mjSIJV4GuHFTLa47zxw3uPvdY8K_rM",
  "appId": "1:1003283386663:web:ef03a9dc41c1f1c1ca1902",
  "authDomain": "schooldog-i-socialcircle-ga.firebaseapp.com",
  "measurementId": "G-X5E9024DS2",
  "messagingSenderId": "1003283386663",
  "project": "schooldog-i-socialcircle-ga",
  "projectId": "schooldog-i-socialcircle-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-socialcircle-ga.appspot.com"
}
